@use "src/styles/theming/colors";

.article-link {
  height: 100%;

  a {
    display: flex;
    align-items: stretch;
    text-decoration: none;
    color: inherit;
    height: 100%;

    app-article-card {
      flex-grow: 1;
    }

    &:hover {
      color: colors.$primary-variant;
    }
  }
}
